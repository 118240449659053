import React, { useState, useCallback, useEffect } from "react";
import { TimelineContext } from "dnd-timeline";
import { Card, Button, notification, Spin, DatePicker, Modal, Radio, Form } from "antd";
import Timeline from "./Timeline";
import { equipments_occupation_get } from "api/equipment";
import { services_by_day_get, service_equipament_put, service_staff_put, service_delete } from "api/service";
import { service_multi_passengers_get } from "api/service";
import { crew_staffs_get } from "api/staff";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { LeftOutlined, RightOutlined, ReloadOutlined } from "@ant-design/icons";
import StaffEditDrawer from "./StaffEditDrawer";
import EquipmentEditDrawer from "./EquipmentEditDrawer";
import moment from "moment-timezone";
import { isNullUndefinedOrEmpty } from "views/app-views/entry/list/helper";

const DEFAULT_TIMEFRAME = {
  start: moment().set("hours", 9).toDate(),
  end: moment().set("hours", 19).toDate(),
};

const OccupationMap = (props) => {
  const [form] = Form.useForm();
  const { readOnly = false } = props;
  const [timeframe, setTimeframe] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [itemRows, setItemRows] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceDate, setServiceDate] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [staffDrawer, setStaffDrawer] = useState({ visible: false, data: null });
  const [equipmentDrawer, setEquipmentDrawer] = useState({ visible: false, data: null });
  const [modalSelectDate, setModalSelectDate] = useState({ visible: false, data: null });
  const [itemMode, setItemMode] = useState(null);

  useEffect(() => {
    init();
    //console.log("OnMount");
  }, []);
  const selectedItemMode = itemMode;
  console.log("zzzzzzzzzzzzz", selectedItemMode);

  useEffect(() => {
    if (serviceDate) loadServices(serviceDate);
  }, [serviceDate]);

  useEffect(() => {
    if (itemMode === "equipment") {
      processItemRows(equipments || []);
    }
    if (itemMode === "staff") {
      processItemRows(staffs || []);
    }

    loadServices(serviceDate);
  }, [itemMode]);

  const init = async () => {
    await loadEquipments();
    await loadStaffs();
    setItemMode("equipment");
    form.setFieldsValue({ itemMode: "equipment" });

    if (readOnly === false) {
      if (!serviceDate)
        setServiceDate(
          moment(new Date()).set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0).toDate()
        );
    } else {
      const now = moment(new Date());
      const sixPM = moment(new Date()).set("hour", 18).set("minute", 0).set("second", 0).set("millisecond", 0);
      let _serviceDate = moment(new Date()).set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0);
      if (now.isAfter(sixPM)) {
        _serviceDate = moment(new Date())
          .add(1, "day")
          .set("hour", 9)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0);
      }
      setServiceDate(_serviceDate);
    }
  };

  const swapRowMethod = (item) => {
    setItemMode(item);
    console.log("swapRowMethod", item);
  };

  const loadStaffs = async () => {
    setIsLoading(true);
    const staffsResponse = await dbGetStaffs();
    if (!staffsResponse.status) {
      notification.error({
        message: "Erro",
        description: "Erro ao carregar staffs...",
      });
      setIsLoading(false);
      return;
    }

    setStaffs(staffsResponse.data);
    setIsLoading(false);

    if (itemMode === "staff") processItemRows(staffsResponse.data);
  };

  const loadEquipments = async () => {
    setIsLoading(true);

    const equipmentsResponse = await dbLoadEquipments();
    if (!equipmentsResponse.status) {
      notification.error({
        message: "Erro ao carregar equipamentos",
        description: "Não foi possível carregar os equipamentos",
      });
      setIsLoading(false);
      return;
    }

    setEquipments(equipmentsResponse.data);
    setIsLoading(false);

    if (itemMode === "equipament") processItemRows(equipmentsResponse.data);
  };

  const processItemRows = (data) => {
    if (!data) {
      setItemRows([]);
      return;
    }

    let rows = data?.map((item) => ({
      id: item._id,
      name: item.name,
      disabled: false,
    }));

    if (!rows) rows = [];

    rows = [
      { id: "empty", name: itemMode === "equipment" ? "Sem Equipamento" : "Sem Staff", disabled: false },
      ...rows,
    ];
    setItemRows(rows);
  };

  const loadServices = async (srv_date) => {
    try {
      if (!srv_date) return;

      setIsLoading(true);

      const service_date = srv_date; // moment(srv_date).set("hour", 9).toDate();

      if (!moment(service_date).isValid()) {
        notification.error({
          message: "Erro",
          description: "Data de serviço inválida",
        });
        setIsLoading(false);
        return;
      }

      const todayServicesResponse = await dbLoadServicesByDay(service_date);
      if (!todayServicesResponse.status) {
        notification.error({
          message: "Erro",
          description: "Erro ao carregar serviços de hoje...",
        });
        setIsLoading(false);
        return;
      }

      let service_raw_ids = todayServicesResponse.data.map((service) => service.service_id_raw);

      service_raw_ids = [...new Set(service_raw_ids)];

      const multiPassengersResponse = await dbGetMultiPassengers(service_raw_ids);
      if (!multiPassengersResponse.status) {
        notification.error({
          message: "Erro",
          description: "Erro ao carregar passageiros...",
        });
        setIsLoading(false);
        return;
      }

      if (readOnly === true) {
        setTimeframe({
          start: moment(service_date).set("hours", 9).add(1, "hour").toDate(),
          end: moment(service_date).set("hours", 19).subtract(1, "hour").toDate(),
        });
      } else {
        setTimeframe({
          start: moment(service_date).set("hours", 9).toDate(),
          end: moment(service_date).set("hours", 19).toDate(),
        });
      }

      let emptyServices =
        itemMode === "equipment"
          ? todayServicesResponse.data.filter((el) => el.equipment.length === 0)
          : todayServicesResponse.data.filter((el) => el.staff.length === 0);

      emptyServices = emptyServices.map((service) => ({
        id: `${service.service_id_raw}--empty`,
        //id: service.service_id_raw,
        rowId: "empty",
        service: service,
        staff: service.staff,
        equipment: service.equipment,
        is_forecast: service.is_forecast,
        passengers: multiPassengersResponse?.data?.filter(
          (passenger) => passenger.service === service.service_id_raw
        ),
        relevance: {
          start: moment(service.final_start_date).set("seconds", 0).set("milliseconds", 0).toDate(),
          end: moment(service.final_end_date).set("seconds", 0).set("milliseconds", 0).toDate(),
        },
        disabled: false,
      }));

      let servicesWithItem = null;
      if (itemMode === "equipment") {
        servicesWithItem = todayServicesResponse.data
          .filter((el) => el.equipment.length > 0)
          .map((service) =>
            service.equipment.map((equipment) => ({
              id: `${service.service_id_raw}--${equipment._id}`,
              // id: service.service_id_raw,
              rowId: equipment._id,

              service: service,
              staff: service.staff,
              is_forecast: service.is_forecast,
              equipment: service.equipment,
              passengers: multiPassengersResponse?.data?.filter(
                (passenger) => passenger.service === service.service_id_raw
              ),
              relevance: {
                start: moment(service.final_start_date).set("seconds", 0).set("milliseconds", 0).toDate(),
                end: moment(service.final_end_date).set("seconds", 0).set("milliseconds", 0).toDate(),
              },
              disabled: false,
            }))
          );
      }

      if (itemMode === "staff") {
        servicesWithItem = todayServicesResponse.data
          .filter((el) => el.staff.length > 0)
          .map((service) =>
            service.staff.map((staff) => ({
              id: `${service.service_id_raw}--${staff._id}`,
              // id: service.service_id_raw,
              rowId: staff._id,

              service: service,
              staff: service.staff,
              is_forecast: service.is_forecast,
              equipment: service.equipment,
              passengers: multiPassengersResponse?.data?.filter(
                (passenger) => passenger.service === service.service_id_raw
              ),
              relevance: {
                start: moment(service.final_start_date).set("seconds", 0).set("milliseconds", 0).toDate(),
                end: moment(service.final_end_date).set("seconds", 0).set("milliseconds", 0).toDate(),
              },
              disabled: false,
            }))
          );
      }

      const currentServices = [...emptyServices, ...servicesWithItem].flat();
      const dummyServices = createDummyServices(currentServices, service_date);
      let serviceItems = [...currentServices, ...dummyServices];

      setServices(serviceItems);
      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao carregar serviços...",
      });
      console.log("Error loading services", error);
      setIsLoading(false);
    }
  };

  const createDummyServices = (data, _serviceDate) => {
    //const _serviceDate = moment(service_date).toDate();

    // Check if service moment date is valid
    if (!moment(_serviceDate).isValid()) {
      notification.error({
        message: "Erro",
        description: "Data de serviço inválida",
      });

      return null;
    }

    const services = data.map((service) => service.service);

    const r1h = [
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "14:00",
      "15:00",
      "15:30",
      "16:00",
      "17:00",
      "18:00",
    ];

    const r2h = ["10:45", "15:00"];

    const fostcastServices = [];

    for (const r of r1h) {
      const _start_date = moment(_serviceDate)
        .set("hours", r.split(":")[0])
        .set("minutes", r.split(":")[1])
        .set("seconds", 0)
        .set("milliseconds", 0)
        .toDate();

      const _end_date = moment(_serviceDate)
        .set("hours", r.split(":")[0])
        .set("minutes", r.split(":")[1])
        .set("seconds", 0)
        .set("milliseconds", 0)
        .add(1, "hour")
        .toDate();

      // Check if service already exists (day, hour and minute)
      const serviceExists = services.find(
        (service) =>
          moment(service.final_start_date).isSame(_start_date, "hour") &&
          moment(service.final_start_date).isSame(_start_date, "minute") &&
          moment(service.final_end_date).isSame(_end_date, "hour") &&
          moment(service.final_end_date).isSame(_end_date, "minute")
      );

      if (serviceExists !== undefined) {
        continue;
      }

      fostcastServices.push({
        id: `dummy-${r}-${moment(_serviceDate).format("YYYY-MM-DD")}`,
        rowId: "empty",
        name: "Regular 1 hora",
        service: {
          service_id_raw: `dummy-${r}-${moment(_serviceDate).format("YYYY-MM-DD")}`,
          final_start_date: _start_date,
          final_end_date: _end_date,
          equipment: [],
          product: "62504122e43310076c57fdc9",
        },
        is_forecast: true,
        staff: [],
        equipment: [],
        passengers: [],
        relevance: {
          start: _start_date,
          end: _end_date,
        },
        disabled: false,
      });
    }

    for (const r of r2h) {
      const _start_date = moment(_serviceDate)
        .set("hours", r.split(":")[0])
        .set("minutes", r.split(":")[1])
        .set("seconds", 0)
        .set("milliseconds", 0)
        .toDate();

      const _end_date = moment(_serviceDate)
        .set("hours", r.split(":")[0])
        .set("minutes", r.split(":")[1])
        .set("seconds", 0)
        .set("milliseconds", 0)
        .add(2, "hour")
        .toDate();

      // Check if service already exists (day, hour and minute)
      const serviceExists = services.find(
        (service) =>
          moment(service.final_start_date).isSame(_start_date, "hour") &&
          moment(service.final_start_date).isSame(_start_date, "minute") &&
          moment(service.final_end_date).isSame(_end_date, "hour") &&
          moment(service.final_end_date).isSame(_end_date, "minute")
      );

      if (serviceExists !== undefined) {
        continue;
      }

      fostcastServices.push({
        id: `dummy-${r}-${moment(_serviceDate).format("YYYY-MM-DD")}`,
        rowId: "empty",
        name: "Regular 2 horas",
        service: {
          service_id_raw: `dummy-${r}-${moment(_serviceDate).format("YYYY-MM-DD")}`,
          final_start_date: _start_date,
          final_end_date: _end_date,
          equipment: [],
          product: "62504122e43310076c57fdcd",
        },
        is_forecast: true,
        staff: [],
        equipment: [],
        passengers: [],
        relevance: {
          start: _start_date,
          end: _end_date,
        },
        disabled: false,
      });
    }

    return fostcastServices;
  };

  const updateEquipament = async (item, equipament) => {
    setIsLoading(true);

    if (equipament.toString() === item.rowId.toString()) {
      setIsLoading(false);
      return;
    }

    const payload = {
      service_id_raw: item.service.service_id_raw,
      add_equipment: equipament,
      remove_equipment: item.rowId,
      item: item,
    };

    const updateResponse = await dbUpdateServiceEquipment(item.service._id, payload);
    if (!updateResponse.status) {
      notification.error({
        message: "Erro ao atualizar",
        description: "Não foi possível atualizar o Equipamento",
      });

      setIsLoading(false);
      return;
    }

    if (item.service?.service_id_raw?.indexOf("dummy") > -1) {
      await loadServices();
    }
    setIsLoading(false);
  };

  const updateStaff = async (item, staff) => {
    setIsLoading(true);

    if (staff.toString() === item.rowId.toString()) {
      setIsLoading(false);
      return;
    }

    const payload = {
      service_id_raw: item.service.service_id_raw,
      add_staff: staff,
      remove_staff: item.rowId,
      item: item,
    };

    const updateResponse = await dbUpdateServiceStaff(item.service._id, payload);
    if (!updateResponse.status) {
      notification.error({
        message: "Erro ao atualizar",
        description: "Não foi possível atualizar o Staff",
      });

      setIsLoading(false);
      return;
    }

    if (item.service?.service_id_raw?.indexOf("dummy") > -1) {
      await loadServices();
    }
    setIsLoading(false);
  };

  const dbLoadEquipments = () => {
    return new Promise((resolve) => {
      try {
        equipments_occupation_get()
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        //  Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const dbGetStaffs = () => {
    return new Promise((resolve) => {
      crew_staffs_get("")
        .then((res) => {
          resolve({ status: true, data: res.data });
        })
        .catch((err) => {
          resolve({ status: false, data: err });
        });
    });
  };

  const dbUpdateServiceEquipment = (_id, payload) => {
    return new Promise((resolve) => {
      try {
        service_equipament_put("", _id, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        //  Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const dbUpdateServiceStaff = (_id, payload) => {
    return new Promise((resolve) => {
      try {
        service_staff_put("", _id, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        //  Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const dbDeleteService = (_id) => {
    return new Promise((resolve) => {
      try {
        service_delete("", _id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        //  Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const dbGetMultiPassengers = (service_raw_ids) => {
    return new Promise((resolve) => {
      try {
        service_multi_passengers_get("", service_raw_ids)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbLoadServicesByDay = (service_date) => {
    return new Promise((resolve) => {
      try {
        services_by_day_get("", moment(service_date).toISOString())
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const onResizeEnd = useCallback(
    (event) => {
      return;
      // const updatedRelevance = event.active.data.current.getRelevanceFromResizeEvent?.(event);

      // if (!updatedRelevance) return;

      // const activeItemId = event.active.id;

      // setItems((prev) =>
      //   prev.map((item) => {
      //     if (item.id !== activeItemId) return item;

      //     return {
      //       ...item,
      //       relevance: updatedRelevance,
      //     };
      //   })
      // );
    },
    [setServices]
  );

  const onResizeMove = useCallback(
    (event) => {
      return;
      // const updatedRelevance = event.active.data.current.getRelevanceFromResizeEvent?.(event);

      // if (!updatedRelevance) return;

      // const activeItemId = event.active.id;

      // setItems((prev) =>
      //   prev.map((item) => {
      //     if (item.id !== activeItemId) return item;

      //     return {
      //       ...item,
      //       relevance: updatedRelevance,
      //     };
      //   })
      // );
    },
    [setServices]
  );

  const onResizeStart = useCallback(
    (event) => {
      return;
      // const updatedRelevance = event.active.data.current.getRelevanceFromResizeEvent?.(event);

      // if (!updatedRelevance) return;

      // const activeItemId = event.active.id;

      // setItems((prev) =>
      //   prev.map((item) => {
      //     if (item.id !== activeItemId) return item;

      //     return {
      //       ...item,
      //       relevance: updatedRelevance,
      //     };
      //   })
      // );
    },
    [setServices]
  );

  const onDragEnd = useCallback(
    (event) => {
      if (readOnly === true) return;
      const activeRowId = event.over?.id;

      const updatedRelevance = event.active.data.current.getRelevanceFromDragEvent?.(event);

      if (!updatedRelevance || !activeRowId) return;

      if (activeRowId.includes("-")) {
        return;
      }

      const activeItemId = event.active.id;
      setServices((prev) =>
        prev.map((item) => {
          if (item.id !== activeItemId) return item;

          let _itemMode = form.getFieldValue("itemMode");
          console.log("setServices Item Mode 1", _itemMode);
          if (isNullUndefinedOrEmpty(_itemMode)) {
            notification.error({
              message: "Erro",
              description: "Modo de item não definido",
            });
          }
          if (!isNullUndefinedOrEmpty(_itemMode)) {
            if (_itemMode === "staff") updateStaff(item, activeRowId);
            if (_itemMode === "equipment") updateEquipament(item, activeRowId);
          }

          return {
            ...item,
            rowId: activeRowId,
          };
        })
      );
    },
    [setServices]
  );

  const getServiceDate = () => {
    const day = moment(serviceDate).isSame(new Date(), "day");
    if (day) return "Hoje";
    return moment(serviceDate).set("hour", 9).format("DD-MM-YYYY");
  };

  const duplicateServiceLine = (item) => {
    console.log("Duplicating service", item);

    const newItem = {
      ...item,
      id: `${item.id}-duplicate`,
      rowId: "empty",
    };
    console.log("New Item", newItem);

    setServices.push([...services, newItem]);
  };

  // function handleDragEnd(event) {
  //   const { active, over } = event;
  //   console.log("handleDragEnd >>>>>", over);
  // }
  // function handleDragStart(event) {
  //   console.log(event.active.id);
  // }

  const handleStaffEditClick = (item) => {
    setStaffDrawer({ visible: true, data: item });
  };

  const handleEquipmentEditClick = (item) => {
    setEquipmentDrawer({ visible: true, data: item });
  };

  const handleOnDeleteClick = async (item) => {
    setIsLoading(true);

    const res = await dbDeleteService(item.service._id);
    if (!res.status) {
      notification.error({
        message: "Erro",
        description: "Erro ao remover serviço (previsão).",
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    loadServices(serviceDate);
  };

  return (
    <Spin spinning={isLoading}>
      <Form form={form} id="frm-occupation">
        <Card
          size={readOnly ? "small" : "default"}
          style={{ borderWidth: readOnly ? 0 : 1 }}
          className={readOnly ? "team-occupation-map-card" : ""}
        >
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 12, alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {readOnly !== true && (
                <Button
                  style={{ width: readOnly ? "60px" : "80px" }}
                  size={readOnly ? "small" : "large"}
                  type="primary"
                  onClick={() => setServiceDate((prev) => moment(prev).subtract(1, "day").set("hour", 9).toDate())}
                >
                  <LeftOutlined />
                </Button>
              )}
              <div
                style={{ marginLeft: 15, marginRight: 15, textAlign: "center" }}
                onClick={() => setModalSelectDate({ visible: true, data: serviceDate })}
              >
                <h3 className="m-0">{getServiceDate()}</h3>
              </div>
              {readOnly !== true && (
                <Button
                  style={{ width: readOnly ? "60px" : "80px" }}
                  size={readOnly ? "small" : "large"}
                  type="primary"
                  onClick={() => setServiceDate((prev) => moment(prev).add(1, "day").set("hour", 9).toDate())}
                >
                  <RightOutlined />
                </Button>
              )}
              <Form.Item name="itemMode" noStyle>
                <Radio.Group
                  size={readOnly ? "small" : "large"}
                  buttonStyle="solid"
                  style={{ marginLeft: 10 }}
                  onChange={(e) => swapRowMethod(e.target.value)}
                >
                  <Radio.Button value="equipment">Equipamento</Radio.Button>
                  <Radio.Button value="staff">Staff</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Button
                size={readOnly ? "small" : "large"}
                style={{ marginLeft: 10 }}
                type="default"
                onClick={() => loadServices(serviceDate)}
              >
                <ReloadOutlined />
              </Button>
            </div>
            <div>
              <Button
                className="mr-2"
                size="small"
                onClick={() => {
                  setTimeframe({
                    start: moment(timeframe.start).subtract(30, "minutes").toDate(),
                    end: moment(timeframe.end).subtract(30, "minutes").toDate(),
                  });
                }}
              >
                {"<<<"}
              </Button>
              <Button
                className="mr-2"
                size="small"
                onClick={() => {
                  setTimeframe({
                    start: moment(timeframe.start).add(30, "minutes").toDate(),
                    end: moment(timeframe.end).add(30, "minutes").toDate(),
                  });
                }}
              >
                {">>>"}
              </Button>
              <Button
                className="mr-2"
                size="small"
                onClick={() => {
                  setTimeframe({
                    start: moment(timeframe.start).subtract(1, "hour").toDate(),
                    end: moment(timeframe.end).add(1, "hour").toDate(),
                  });
                }}
              >
                {"-"}
              </Button>
              <Button
                className="mr-2"
                size="small"
                onClick={() => {
                  setTimeframe({
                    start: moment(timeframe.start).add(1, "hour").toDate(),
                    end: moment(timeframe.end).subtract(1, "hour").toDate(),
                  });
                }}
              >
                {"+"}
              </Button>

              {/* <span style={{ marginRight: 20 }}>
              Zoom In / Out: <code>Ctrl</code> + <code>scroll</code>
            </span>
            <span>
              Mover Direita / Esquerda: <code>Ctrl</code> + <code>⇧</code> + <code>scroll</code>
            </span> */}
            </div>
          </div>
          {timeframe !== null && (
            <TimelineContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
              onResizeStart={onResizeStart}
              onResizeMove={onResizeMove}
              onResizeEnd={onResizeEnd}
              onTimeframeChanged={setTimeframe}
              timeframe={timeframe}
              onDragStart={(event) => {
                if (readOnly === true) return false;
              }}
            >
              <Timeline
                // staffs={staffs}
                // equipments={equipments}
                readOnly={readOnly}
                items={services}
                rows={itemRows}
                onDuplicate={() => console.log("DUP")}
                onStaffEditClick={handleStaffEditClick}
                onEquipmentEditClick={handleEquipmentEditClick}
                onDeleteClick={handleOnDeleteClick}
                itemMode={itemMode}
              />
            </TimelineContext>
          )}
        </Card>
        <StaffEditDrawer
          staffs={staffs}
          visible={staffDrawer.visible}
          data={staffDrawer.data}
          close={(status) => {
            setStaffDrawer({ visible: false, data: null });
            loadServices(serviceDate);
          }}
        />
        <EquipmentEditDrawer
          equipments={equipments}
          visible={equipmentDrawer.visible}
          data={equipmentDrawer.data}
          close={(status) => {
            setEquipmentDrawer({ visible: false, data: null });
            loadServices(serviceDate);
          }}
        />

        <Modal
          title="Selecionar Data"
          visible={modalSelectDate.visible}
          onOk={() => setModalSelectDate({ visible: false, data: null })}
          onCancel={() => setModalSelectDate({ visible: false, data: null })}
          footer={<Button onClick={() => setModalSelectDate({ visible: false, data: null })}>Fechar</Button>}
        >
          <DatePicker
            size="large"
            value={moment(modalSelectDate.data)}
            onChange={(date) => {
              setServiceDate(moment(date).set("hour", 9).toDate());
              setModalSelectDate({ visible: false, data: null });
            }}
          />
        </Modal>
      </Form>
    </Spin>
  );
};

export default OccupationMap;
