import React, { useEffect, useState } from "react";
import { service_get, service_staffs_or_equipment_put } from "api/service";
import { Drawer, Button, notification, Spin, Checkbox } from "antd";

const StaffEditDrawer = ({ visible, data, close, staffs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [service, setService] = useState(null);
  const [selectedStaffs, setSelectedStaffs] = useState([]);

  useEffect(() => {
    return () => {
      setService(null);
      setIsLoading(false);
      setSelectedStaffs([]);
    };
  }, []);

  useEffect(() => {
    if (visible && data.service?._id) {
      loadService(data.service?._id);
    }

    return () => {
      setService(null);
      setIsLoading(false);
      setSelectedStaffs([]);
    };
  }, [visible]);

  const loadService = async (service_id) => {
    setIsLoading(true);
    const res = await dbGetService(service_id);
    if (!res.status) {
      notification.error({
        message: "Erro",
        description: "Erro ao carregar serviço.",
      });
      setIsLoading(false);
      return;
    }

    setService(res.data);
    setSelectedStaffs(res.data.staff);
    setIsLoading(false);
  };

  const updateServiceStaffs = async () => {
    setIsLoading(true);

    const payload = {
      service_id_raw: data.service.service_id_raw,
      item: data,
      staff: selectedStaffs,
      mode: "staff",
    };

    const res = await dbUpdateServiceStaffs(data?.service?._id, payload);
    if (!res.status) {
      notification.error({
        message: "Erro",
        description: "Erro ao atualizar Staff.",
      });
      setIsLoading(false);
      return;
    }

    notification.success({
      message: "Sucesso",
      description: "Staff atualizado com sucesso.",
    });

    setIsLoading(false);
    close(true);
  };

  const dbGetService = async (_id) => {
    return new Promise((resolve) => {
      try {
        service_get("", _id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const dbUpdateServiceStaffs = async (service_id, data) => {
    return new Promise((resolve) => {
      try {
        service_staffs_or_equipment_put("", service_id, data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  return (
    <Drawer
      width={600}
      title={`Staff do Serviço ${data?.service?.service_id_raw ? data?.service?.service_id_raw : "Previsão"}`}
      placement="right"
      closable={true}
      onClose={close}
      visible={visible}
      footer={[
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button key="cancel" disabled={isLoading} onClick={close} style={{ marginRight: 8 }}>
            Cancelar
          </Button>

          <Button key="confirm" type="primary" disabled={isLoading} onClick={() => updateServiceStaffs()}>
            Confirmar
          </Button>
        </div>,
      ]}
    >
      <Spin spinning={isLoading}>
        <div>
          <div style={{ marginLeft: 20 }}>
            {staffs.map((staff) => {
              let staffAbbr = staff.name;
              let StaffName = "";
              if (staffAbbr.includes(" - ")) {
                staffAbbr = staff.name.split(" - ")[1];
                StaffName = staff.name.split(" - ")[0];
              }
              return (
                <div key={staff._id}>
                  <Checkbox
                    key={staff._id}
                    checked={selectedStaffs.includes(staff._id)}
                    onChange={(e) => {
                      setSelectedStaffs(
                        e.target.checked
                          ? [...selectedStaffs, staff._id]
                          : selectedStaffs.filter((s) => s !== staff._id)
                      );
                    }}
                  >
                    <strong>{staffAbbr}</strong> {StaffName !== "" ? ` - ${StaffName}` : ""}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default StaffEditDrawer;
