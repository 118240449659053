// export const accessTypes = [
//   { id: 99, name: "Administrador" },
//   { id: 8, name: "Gestor" },
//   { id: 7, name: "Administrativo" },
//   { id: 6, name: "Secretariado" },
//   { id: 5, name: "Mobile: Despesas" },
//   { id: 1, name: "Cais" },
//   { id: 4, name: "Serviço / Mapa de Embarques" },
//   { id: 3, name: "Serviço / Ocupação" },
//   { id: 2, name: "Tabelas Base / Vouchers" },
//   { id: 0, name: "Comprador" },
// ];

// export const getRoleRank = (roles) => {
//   // return role id

//   if (!roles) return -1;

//   // Get greater role from roles Array

//   let roleRank = -1;
//   for (const role of roles) {
//     const roleItem = accessTypes.find((r) => r.name.toLowerCase() === role.toLowerCase());
//     if (roleItem.id > roleRank) {
//       roleRank = roleItem.id;
//     }
//   }

//   return roleRank;
// };

export const accessTypes = [
  "Administrador",
  "Gestor",
  "Administrativo",
  "Secretariado",
  "Cais",
  "Comprador",
  "Mobile: Despesas",
  "Ocupação (ViewOnly)",
];

const rolesRank = {
  Administrador: 5,
  Gestor: 4,
  Administrativo: 3,
  Secretariado: 3,
  Cais: 1,
  Comprador: 0,
  "Mobile: Despesas": 0,
  "Ocupação (ViewOnly)": -1,
};

export const getRoleRank = (roles) => {
  console.log("getRoleRank", roles);
  let rank = 1;
  if (!roles) return rank;

  roles.forEach((role) => {
    if (rolesRank[role] > rank) {
      rank = rolesRank[role];
    }
  });

  return rank;
};
