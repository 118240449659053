import { axiosAPIRequest, checkAuth } from "../index";

export const auth_user = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/auth/auth-user", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const login_user = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/auth/login", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const otp_auth_user = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/auth/otp-auth", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const otp_request_user = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/auth/otp-request", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const otp_authenticate_user = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/auth/otp-authenticate", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
