import antdPtPT from "antd/es/locale/pt_PT";
import ptMsg from "../locales/pt_PT.json";

const PtLang = {
  antd: antdPtPT,
  locale: "pt-PT",
  messages: {
    ...ptMsg,
  },
};
export default PtLang;
