import axios from "axios";

import axiosRetry from "axios-retry";

const api_request = () => {
  const _axios = new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  axiosRetry(_axios, { retries: 3, shouldResetTimeout: true });
  return _axios;
};

const checkAuth = (resp) => {
  //TODO:
  //Impement on components on catch: If status is 401 execute Google Signout
  // console.log(resp.response.status);
};

export const servicesV2_get = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/service/v2", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const services_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    let _filter = "";
    if (filter !== undefined) {
      _filter = `/?filter=${filter}`;
    }
    api_request(auth_data)
      .get(`/service${_filter}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const services_passengers_piers_changes_get = async (auth_data, service_id_raw) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/passengers/pier-changes/${service_id_raw}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const services_active_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/service/active")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const services_search_get = async (auth_data, text) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/search/?search=${text}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const services_by_day_get = async (auth_data, service_date) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/by-day?service_date=${service_date}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
export const services_by_day_single_get = async (auth_data, service_id_raw) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/by-day-single?service_id=${service_id_raw}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_get = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_atual_by_raw_id_get = async (auth_data, raw_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/actual-by-raw-id/${raw_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_navigation_get = async (auth_data, service_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/navigation/${service_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_passengers_get = async (auth_data, service_raw_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/passengers/${service_raw_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_multi_passengers_get = async (auth_data, service_raw_ids) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/passengers/multi/${service_raw_ids}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_passengers_post = async (auth_data, data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/service/passengers", data)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_passengers_delete = async (auth_data, _id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/service/passengers/${_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_passengers_put = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/service/passengers/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_staffs_or_equipment_put = async (auth_data, _id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/service/staffs-or-equipment/${_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/service", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

// export const service_post_newversion = async (auth_data, service_id, body) => {
//   return new Promise((resolve, reject) => {
//     api_request(auth_data)
//       .post(`/service/${service_id}`, body)
//       .then((res) => resolve(res))
//       .catch((err) => {
//         checkAuth(err);
//         reject(err);
//       });
//   });
// };

export const service_put = async (auth_data, _id, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/service/${_id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_equipament_put = async (auth_data, _id, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/service/equipment/${_id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_staff_put = async (auth_data, _id, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/service/staff/${_id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_delete = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/service/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_by_raw_id_delete = async (auth_data, raw_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/service/by-raw-id/${raw_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_export_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/service/export", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

// export const services_embarkation_map_get = async (auth_data, query) => {
//   return new Promise((resolve, reject) => {
//     api_request(auth_data)
//       .get("/service/embarkation-map", )
//       .then((res) => resolve(res))
//       .catch((err) => {
//         checkAuth(err);
//         reject({ ...err });
//       });
//   });
// };

export const services_embarkation_map_put = async (auth_data, _id, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/service/embarkation-map/${_id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const services_embarkation_map_get = async (
  auth_data,
  limit,
  page,
  filter,
  select,
  orderBy,
  orderDirection
) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/service/embarkation-map", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_regular_by_day_get = async (auth_data, services_date) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/service/regular-by-day/${services_date}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const service_transfer_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/service/transfer-service", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
