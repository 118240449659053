import React from "react";
import { useItem } from "dnd-timeline";
import { UserSwitchOutlined, DeleteOutlined, CompassOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Tag, Button, Tooltip } from "antd";
import moment from "moment-timezone";
//import { useDroppable } from "@dnd-kit/core";

function Item({
  id,
  rowId,
  relevance,
  children,
  staffs,
  equipments,
  onStaffEditClick,
  onEquipmentEditClick,
  item,
  onDeleteClick,
  itemMode,
  readOnly,
}) {
  const { setNodeRef, attributes, listeners, itemStyle, itemContentStyle } = useItem({
    id: id,
    rowId: rowId,
    relevance: relevance,
    itemContentStyle: {
      minHeight: 90,
    },
  });

  const isEmpty = rowId === "empty" && id.indexOf("dummy") === -1;
  const isDummy = rowId === "empty" && id.indexOf("dummy") !== -1;
  const isDummyWithEquip = rowId !== "empty" && id.indexOf("dummy") !== -1;
  const isForecast = item.service?.is_forecast === true;
  const hasEquipment = item.service?.equipment?.length > 0;
  const hasStaff = staffs?.length > 0;

  //console.log("itemMode", itemMode);

  let itemColor = "#cceeff";
  let itemBorderColor = `2px solid #54a7db`;

  if (isEmpty) {
    itemColor = "#efefef";
    itemBorderColor = `2px solid #dbdbdb`;
  }

  if (isDummy) {
    itemColor = "#f8f0ff";
    itemBorderColor = `2px dashed #cb8fff`;
  }

  if (isDummyWithEquip) {
    itemColor = "#cceeff";
    itemBorderColor = `2px dashed #54a7db`;
  }

  if (isForecast && isEmpty) {
    itemColor = "#f8f0ff";
    itemBorderColor = `2px dashed #d291ff`;
  }

  if (isForecast && hasEquipment) {
    itemColor = "#edd3ff";
    itemBorderColor = `2px dashed #d291ff`;
  }

  const StaffTag = ({ staff }) => {
    let staffAbbr = staff.name;

    if (staffAbbr.includes(" - ")) {
      staffAbbr = staff.name.split(" - ")[1];
    }
    return (
      <Tooltip title={staff.name}>
        <Tag className="smallTag2" color="#2db7f5" key={staff._id}>
          {staffAbbr}
        </Tag>
      </Tooltip>
    );
  };

  const EquipmentTag = ({ equipment }) => {
    let equipmentAbbr = equipment.name;

    if (equipmentAbbr.includes(" - ")) {
      equipmentAbbr = equipment.name.split(" - ")[1];
    }
    return (
      <Tooltip title={equipment.name}>
        <Tag className="smallTag2" color="#2db7f5" key={equipment._id}>
          {equipmentAbbr}
        </Tag>
      </Tooltip>
    );
  };

  return (
    <div {...attributes} style={{ ...itemStyle }}>
      {isDummy === false && isDummyWithEquip === false && (
        <>
          {readOnly === false && (
            <div style={{ textAlign: "right" }}>
              <small className="clickable_hover mr-1">
                <Tooltip title={`Serviço: ${item.service.service_id_raw}`}>
                  <InfoCircleOutlined />
                </Tooltip>
              </small>
              {/* <Button
              style={{ height: 23, padding: 0, margin: 0, marginRight: 8 }}
              type="link"
              onClick={() => onDeleteClick(item)}
            >
              <small>
                <DeleteOutlined />
              </small>
            </Button> */}
              {itemMode === "equipment" && (
                <Button
                  style={{ height: 23, padding: 0, margin: 0 }}
                  type="link"
                  onClick={() => onStaffEditClick(item)}
                >
                  <small>
                    Gerir Staff <UserSwitchOutlined />
                  </small>
                </Button>
              )}
              {itemMode === "staff" && (
                <Button
                  style={{ height: 23, padding: 0, margin: 0 }}
                  type="link"
                  onClick={() => onEquipmentEditClick(item)}
                >
                  <small>
                    Gerir Equip. <CompassOutlined />
                  </small>
                </Button>
              )}
            </div>
          )}
          <div ref={setNodeRef} {...listeners}>
            <div style={itemContentStyle}>
              <div
                style={{
                  padding: 5,
                  margin: 1,
                  border: itemBorderColor,
                  backgroundColor: itemColor,
                  borderRadius: 6,
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                {children}
                {/* {staffs?.map((staff, index) => (
                  <StaffTag key={index} staff={staff} />
                ))} */}
                {itemMode === "equipment" && (
                  <>
                    {staffs?.map((staff, index) => (
                      <StaffTag key={index} staff={staff} />
                    ))}
                  </>
                )}

                {itemMode === "staff" && (
                  <>
                    {equipments?.map((equipment, index) => (
                      <EquipmentTag key={index} equipment={equipment} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {(isDummy === true || isDummyWithEquip === true) && (
        <>
          {readOnly === false && (
            <div style={{ textAlign: "right" }}>
              <small className="clickable_hover mr-1">
                <Tooltip title="Este item é um espaço vazio na timeline.">
                  <InfoCircleOutlined />
                </Tooltip>
              </small>
              {/* <Button
              style={{ height: 23, padding: 0, margin: 0, marginRight: 8 }}
              type="link"
              onClick={() => onDeleteClick(item)}
            >
              <small>
                <DeleteOutlined />
              </small>
            </Button> */}
              {itemMode === "equipment" && (
                <Button
                  style={{ height: 23, padding: 0, margin: 0 }}
                  type="link"
                  onClick={() => onStaffEditClick(item)}
                >
                  <small>
                    Gerir Staff <UserSwitchOutlined />
                  </small>
                </Button>
              )}
              {itemMode === "staff" && (
                <Button
                  style={{ height: 23, padding: 0, margin: 0 }}
                  type="link"
                  onClick={() => onEquipmentEditClick(item)}
                >
                  <small>
                    Gerir Equip. <CompassOutlined />
                  </small>
                </Button>
              )}
            </div>
          )}
          <div ref={setNodeRef} {...listeners}>
            <div style={itemContentStyle}>
              <div
                style={{
                  padding: 5,
                  margin: 1,
                  border: itemBorderColor,
                  backgroundColor: itemColor,
                  borderRadius: 6,
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="occupation-item-product">{item?.name}</div>
                </div>
                <p className="occupation-item-forecast">
                  Previsão {moment(relevance.start).format("HH:mm")} às {moment(relevance.end).format("HH:mm")}{" "}
                </p>
                {itemMode === "equipment" && (
                  <>
                    {staffs?.map((staff, index) => (
                      <StaffTag key={index} staff={staff} />
                    ))}
                  </>
                )}

                {itemMode === "staff" && (
                  <>
                    {equipments?.map((equipment, index) => (
                      <EquipmentTag key={index} equipment={equipment} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Item;
