import { useRow } from "dnd-timeline";
import React from "react";

function Row(props) {
  const { setNodeRef, setSidebarRef, rowWrapperStyle, rowStyle, rowSidebarStyle } = useRow({ id: props.id });
  const { readOnly } = props;
  return (
    <div style={{ ...rowWrapperStyle, minHeight: readOnly ? 120 : 140 }}>
      <div ref={setSidebarRef} style={{ ...rowSidebarStyle, backgroundColor: "#fafafa" }}>
        {props.sidebar}
      </div>
      <div ref={setNodeRef} style={{ ...rowStyle, border: "1px solid #c2c2c2" }}>
        {props.children}
      </div>
    </div>
  );
}

export default Row;
