import React from "react";
import { notification } from "antd";
import { SocketContext } from "./SocketIOContext";
import { useAuth } from "../../services/Auth";
import { getRoleRank } from "utils/roles";

const SocketIODispatcher = () => {
  const { auth } = useAuth();
  const socket = React.useContext(SocketContext);
  //console.log("SocketIODispatcher", auth.data.email);

  notification.config({
    // maxCount: 3,
  });

  socket.on("connection", (socket) => {
    console.log("connected", socket.id);
  });

  socket.on("MessageBar", (data) => {
    const mdauth = JSON.parse(window.localStorage.getItem("md-auth"));

    if (mdauth?.role?.includes("Cais")) {
      return <></>;
    }

    if (data.exclude !== undefined && data.exclude === auth.data._id) return;

    // Show notification
    notification[data.type]({
      key: data.key || data.message,
      message: data.message || undefined,
      description: data.description || undefined,
      duration: data.duration || 5,
      placement: "bottomLeft",
    });
  });

  return <></>;
};

export default SocketIODispatcher;
