import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const IntlMessage = (props) => {
  let _props = { ...props };
  // If Id is empty
  //console.log("IntlMessage:", _props.id);
  if (_props.id === "") return "";

  return <FormattedMessage {...props} />;
};
export default injectIntl(IntlMessage, {
  withRef: false,
});
