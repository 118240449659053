import React, { useState } from "react";
import { Button } from "antd";
import { useAuth } from "../services/Auth";

const LogoutPage = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { setAuth } = useAuth();

  const logout = () => {
    setIsLoggingOut(true);
    window.localStorage.removeItem("md-auth");
    setAuth({ isSignedIn: false, data: null });
    setTimeout(() => {
      window.location.href = "/home";
    }, 2500);
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Sessão MD</h1>
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Button type="primary" onClick={logout} loading={isLoggingOut}>
          Terminar Sessão
        </Button>
      </div>
      <a href="/home">Voltar</a>
    </div>
  );
};

export default LogoutPage;
