import { groupItemsToSubrows, useTimelineContext } from "dnd-timeline";
import React, { useMemo } from "react";
import { Affix, Tag } from "antd";
import Row from "./Row";
import Sidebar from "./Sidebar";
import Item from "./Item.js";
import Subrow from "./Subrow";
import TimeAxis from "./TimeAxis";
import TimeCursor from "./TimeCursor";
import { parsePassengers } from "./helper";
import { hoursToMilliseconds, format, minutesToMilliseconds } from "date-fns";
import moment from "moment-timezone";
const timeAxisMarkers = [
  {
    value: hoursToMilliseconds(24),
    getLabel: (date) => format(date, "E"),
  },
  {
    value: hoursToMilliseconds(2),
    minTimeframeSize: hoursToMilliseconds(24),
    getLabel: (date) => <>{`${format(date, "k")}h`}</>,
  },
  {
    value: hoursToMilliseconds(1),
    minTimeframeSize: hoursToMilliseconds(24),
  },
  {
    value: hoursToMilliseconds(1),
    maxTimeframeSize: hoursToMilliseconds(24),
    getLabel: (date) => <>{`${format(date, "k")}h`}</>,
  },
  {
    value: minutesToMilliseconds(30),
    maxTimeframeSize: hoursToMilliseconds(24),
    minTimeframeSize: hoursToMilliseconds(12),
  },
  {
    value: minutesToMilliseconds(30),
    maxTimeframeSize: hoursToMilliseconds(12),
    getLabel: (date) => <small>{format(date, "H:m")}</small>,
  },
  // {
  //   value: minutesToMilliseconds(5),
  //   maxTimeframeSize: hoursToMilliseconds(6),
  //   minTimeframeSize: hoursToMilliseconds(3),
  // },
  // {
  //   value: minutesToMilliseconds(5),
  //   maxTimeframeSize: hoursToMilliseconds(3),
  //   getLabel: (date) => format(date, "m"),
  // },
  // {
  //   value: minutesToMilliseconds(1),
  //   maxTimeframeSize: hoursToMilliseconds(2),
  // },
];

const Paxs = ({ passengers }) => {
  const { pax_i, pax_m, pax_z } = parsePassengers(passengers);
  return (
    <span>
      <strong>{pax_i}</strong>/<strong>{pax_m}</strong>/<strong>{pax_z}</strong>
    </span>
  );
};

function Timeline({
  items,
  rows,
  onDuplicate,
  staffs,
  equipments,
  onStaffEditClick,
  onEquipmentEditClick,
  onDeleteClick,
  itemMode,
  readOnly,
}) {
  const { setTimelineRef, style, timeframe } = useTimelineContext();

  const groupedSubrows = useMemo(() => groupItemsToSubrows(items, timeframe), [items, timeframe]);

  // Os staff ficam no topo e mantem-se fixos
  // Vários staff podem ser alocados a um serviço
  // Verificar se o staff está disponível para o serviço (horário e serviço/equipamento)

  return (
    <>
      {/* <DndContext onDragEnd={handleDragEnd} handleDragStart={handleDragStart}>
        <div>
          <div style={{ marginLeft: 20 }}>
            {staffs.map((staff) => {
              let staffName = staff.name;
              if (staffName.includes(" - ")) {
                staffName = staffName.split(" - ")[1];
              }
              return <StaffDraggable key={staff._id} id={staff._id} content={staffName} />;
            })}
          </div>
        </div> */}
      <div ref={setTimelineRef} style={style}>
        <div className="page-pier-dashboard">
          <Affix className="occupation-affix" offsetTop={readOnly ? 0 : 40} style={{ top: readOnly ? 0 : 70 }}>
            <div
              style={{ padding: 2, paddingTop: 10, paddingBottom: 10, backgroundColor: "#fff" }}
              className="shadow-affix-card"
            >
              <TimeAxis markers={timeAxisMarkers} />
            </div>
          </Affix>
        </div>
        <TimeCursor />
        {rows.map((row) => (
          <Row
            id={row.id}
            key={row.id}
            readOnly={readOnly}
            sidebar={<Sidebar row={row} groupedSubRows={groupedSubrows[row.id]} readOnly={readOnly} />}
          >
            {groupedSubrows[row.id]?.map((subrow, index) => (
              <Subrow key={row.id} readOnly={readOnly}>
                {subrow.map((item, index) => {
                  const paxI = item?.passengers.reduce((acc, curr) => acc + curr.pax_i, 0);
                  const paxM = item?.passengers.reduce((acc, curr) => acc + curr.pax_m, 0);
                  const paxZ = item?.passengers.reduce((acc, curr) => acc + curr.pax_z, 0);

                  const totalIMZ = paxI + paxM + paxZ;
                  const equipamentsCapacity = item?.equipment?.reduce((acc, curr) => {
                    if (curr.capacity) {
                      return acc + curr.capacity;
                    } else {
                      return acc;
                    }
                  }, 0);

                  // Check if the total of passengers is more than 90% the total of the equipment capacity
                  const isOverCapacity90 = totalIMZ > equipamentsCapacity * 0.9;
                  const isOverCapacity = totalIMZ > equipamentsCapacity && equipamentsCapacity > 0;

                  let capacityColor = "green";
                  if (isOverCapacity90) {
                    capacityColor = "#a28c00";
                  }
                  if (isOverCapacity) {
                    capacityColor = "#b00000";
                  }
                  if (equipamentsCapacity === 0) {
                    capacityColor = "grey";
                  }

                  return (
                    <Item
                      readOnly={readOnly}
                      onStaffEditClick={onStaffEditClick}
                      onEquipmentEditClick={onEquipmentEditClick}
                      onDeleteClick={onDeleteClick}
                      rowId={row.id}
                      id={item.id}
                      item={item}
                      key={item.id}
                      relevance={item.relevance}
                      equipmentLength={item?.service?.equipment?.length || 0}
                      staffs={item?.staff}
                      equipments={item?.equipment}
                      itemMode={itemMode}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="occupation-item-product">{item?.service?.product?.name}</div>
                      </div>
                      <div style={{ fontSize: readOnly ? 9 : 11 }}>
                        <span>{moment(item.relevance.start).format("HH:mm")}</span>
                        <strong> - </strong>
                        <span>{moment(item.relevance.end).format("HH:mm")}</span>
                      </div>
                      <div className="item-pax-info">
                        PAX: <Paxs passengers={item?.passengers} />{" "}
                        <span style={{ marginLeft: 6, color: capacityColor }}>
                          <small>
                            <strong>Cap.:</strong>
                            {equipamentsCapacity}
                          </small>
                        </span>
                      </div>
                    </Item>
                  );
                })}
              </Subrow>
            ))}
          </Row>
        ))}
      </div>
      {/* </DndContext> */}
    </>
  );
}

export default Timeline;
