import React from "react";
import moment from "moment-timezone";

function minutes_to_hhmm(numberOfMinutes) {
  if (numberOfMinutes === null) return;
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, "minutes");

  //calculate hours
  var hh = duration.years() * (365 * 24) + duration.months() * (30 * 24) + duration.days() * 24 + duration.hours();

  //get minutes
  var mm = duration.minutes();

  //return total time in hh:mm format
  return hh + ":" + mm;
}

function Sidebar(props) {
  const flattenRows = props?.groupedSubRows?.map((row) => row).flat();
  const services = flattenRows?.map((row) => row.service).flat();
  const readOnly = props?.readOnly;

  // remove undefined form services array
  const servicesFiltered = services?.filter((service) => service !== undefined) || [];

  let totalTime = null;

  for (const service of servicesFiltered) {
    const start = moment(service.final_start_date);
    const end = moment(service.final_end_date);
    const duration = moment.duration(end.diff(start));
    totalTime += duration.asMinutes();
  }

  return (
    <>
      {readOnly === true ? (
        <div style={{ padding: 0, display: "grid", alignItems: "center", width: 100, border: "1px solid #c2c2c2" }}>
          <div style={{ display: "inline-block", alignItems: "center", textAlign: "center" }}>
            <small>{props.row?.name}</small>
            <br />
            {totalTime && (
              <small className="text-muted">
                <strong>{moment().startOf("day").add(totalTime, "minutes").format("HH[h] mm[m]")}</strong>
              </small>
            )}
          </div>
        </div>
      ) : (
        <div style={{ padding: 5, display: "grid", alignItems: "center", width: 180, border: "1px solid #c2c2c2" }}>
          <div style={{ display: "inline-block", alignItems: "center", textAlign: "center" }}>
            <span>{props.row?.name}</span>
            <br />
            {totalTime && (
              <span className="text-muted">
                <strong>{moment().startOf("day").add(totalTime, "minutes").format("HH[h] mm[m]")}</strong>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
