import axios from "axios";
import axiosRetry from "axios-retry";

const getMDAuth = () => {
  try {
    const mdauth = JSON.parse(window.localStorage.getItem("md-auth"));
    return mdauth;
  } catch (error) {
    return {};
  }
};

export const axiosAPIRequest = () => {
  try {
    const mdauth = getMDAuth();
    const _axios = new axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      headers: {
        user: mdauth?.user || "",
        token: mdauth?.token || "",
        "Content-Type": "application/json",
      },
    });
    axiosRetry(_axios, { retries: 3, shouldResetTimeout: true });
    return _axios;
  } catch (error) {
    console.log("AxiosAPIRequest Error", error);
    return null;
  }
};

export const checkAuth = (resp) => {
  console.log("RESP", resp);
  // if (resp.response.status === 401) {
  //   window.localStorage.removeItem("auth_token");
  //   window.localStorage.removeItem("md-auth");
  //   window.location.href = "/auth/login";
  //   return false;
  // }
};
