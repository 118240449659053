import { Popover, Avatar, Badge } from "antd";
import moment from "moment-timezone";
import { locale } from "lang/locales/moment_pt_PT";
moment.locale("pt", locale);
export const memberIds = ["9999", "9998", "9997"];

const staffPicture = (data) => {
  if (data === undefined || data === null) return null;
  if (data?.picture !== undefined) {
    return data.picture;
  }
  return `https://api.dicebear.com/7.x/initials/svg?seed=${data?.name}`;
};

export const StaffsActivity = ({ data, size, chain }) => {
  if (!data) return <></>;
  if (data.length === 0) return <></>;
  if (!Array.isArray(data)) return <></>;

  return data.map((member, i) => {
    //console.log("AAAAAAAAAAAAA", member);
    // const member = membersDetail.filter((elm) => id === elm.id)[0];
    if (member.last_activity === undefined) member.last_activity = moment.utc().add(-1, "days").format();
    const member_time = moment.utc(member.last_activity).local();
    const last_activity_min = moment().diff(member_time, "minutes");

    const badge_color = last_activity_min > 60 ? "red" : last_activity_min > 5 ? "gold" : "green";

    return (
      <div className={`d-flex ${chain ? "ml-n2" : ""}`} key={member._id}>
        <Popover
          content={
            <>
              <span>
                Função: <strong>{member.access_type[0]}</strong>
              </span>
              <br />
              <span>
                Última Atividade: <strong>{moment.utc(member.last_activity).local().fromNow()}</strong>
              </span>
            </>
          }
          title={
            <>
              <Badge dot color={badge_color} /> {member.name}
            </>
          }
          trigger="hover"
        >
          <Badge dot color={badge_color} style={{ top: "20px", right: "8px" }}>
            <Avatar
              className="cursor-pointer"
              size={size}
              src={staffPicture(member)}
              style={chain ? { border: "2px solid #fff" } : {}}
            />
          </Badge>
        </Popover>
      </div>
    );
  });
};
