import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import AuthProvider from "services/Auth";

import { UpdateNotification } from "react-update-popup";
import "react-update-popup/dist/index.css";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  // Please make sure to call this method only once!

  return (
    <div className="App">
      <AuthProvider>
        <Provider store={store}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </ThemeSwitcherProvider>
        </Provider>
      </AuthProvider>

      <UpdateNotification
        title="Nova versão disponível"
        description="Atualiza a página para usares a última versão."
        buttonText="Atualizar página"
      />
    </div>
  );
}

export default App;
