import { DATE_FORMAT_DD_MM_YYYY_HH_mm } from "../../../../constants/DateConstant";
import moment from "moment-timezone";

export const ServiceDates = ({ record }) => {
  if (!record) return null;
  const _start_date = moment.utc(record.start_date);
  const _end_date = moment.utc(record.end_date);

  const duration = moment.duration(_end_date.diff(_start_date));

  //console.log("Duration", record.start_date, record.end_date);
  let hours = parseInt(duration.asHours());
  let minutes = parseInt(duration.asMinutes()) % 60;
  hours = Math.abs(hours);
  minutes = Math.abs(minutes);

  return (
    <span>
      {moment(record.start_date).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}, duração: {hours}h
      {minutes > 0 ? ` ${minutes}m` : ""}
    </span>
  );
};

export const Passengers = ({ record }) => {
  if (!record) return null;
  return (
    <span>
      {record.passengersList
        ? record.passengersList.reduce((curr, next) => curr + next.pax_i + next.pax_m + next.pax_z, 0)
        : 0}{" "}
      <small>
        ({record.passengersList ? record.passengersList.reduce((curr, next) => curr + next.pax_i, 0) : 0} /{" "}
        {record.passengersList ? record.passengersList.reduce((curr, next) => curr + next.pax_m, 0) : 0} /{" "}
        {record.passengersList ? record.passengersList.reduce((curr, next) => curr + next.pax_z, 0) : 0})
      </small>
    </span>
  );
};

export const isNullUndefinedOrEmpty = (value) => {
  return value === null || value === undefined || value === "";
};
