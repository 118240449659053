import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { Button, ConfigProvider, Modal } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import { useAuth } from "../services/Auth";
import { staff_update_last_activity_post } from "../api/staff";
import moment from "moment-timezone";
import Logout from "./logout";
import TeamOccupation from "./team-occupation";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { auth } = useAuth();
  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];

  useBodyClass(`dir-${direction}`);
  let timer;

  const updateStaffLastActivity = async () => {
    const _id = auth?.data?._id;
    if (_id === undefined) return;
    localStorage.setItem("md.user.lastUpdate", moment.utc().toISOString());
    staff_update_last_activity_post("", _id)
      .then((res) => {})
      .catch((err) => {});
  };
  clearTimeout(timer);

  timer = setTimeout(() => {
    let lastUpdate = localStorage.getItem("md.user.lastUpdate");

    if (lastUpdate === null) {
      updateStaffLastActivity();
      return;
    }
    lastUpdate = moment.utc(lastUpdate);
    const now = moment.utc();

    // Difference between last update and now
    const diff = now.diff(lastUpdate, "seconds");

    if (diff > 60) {
      updateStaffLastActivity();
    }
  }, 30000);

  const mdAuth = JSON.parse(window.localStorage.getItem("md-auth"));
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            {mdAuth?.onlyPierApp === true ? (
              <Redirect to={`${APP_PREFIX_PATH}/pier-dashboard`} />
            ) : (
              <Redirect to={`${APP_PREFIX_PATH}/home`} />
            )}
          </Route>

          <Route path={`${APP_PREFIX_PATH}/logout`}>
            <Logout />
          </Route>

          <Route path={`${APP_PREFIX_PATH}/team-occupation`}>
            <TeamOccupation />
          </Route>

          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} location={location} />
          </Route>

          {/* <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} />
          </Route> */}

          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={auth.isSignedIn}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
