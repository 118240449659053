import React, { useState, useEffect } from "react";
import { StaffsActivity } from "./ActiveUsers";
import { SocketContext } from "../../views/app-views/SocketIOContext";

const SocketIONavPanelDispatcher = (props) => {
  const socket = React.useContext(SocketContext);

  const getLsStaffsActivity = () => {
    try {
      return JSON.parse(window.localStorage.getItem("StaffsActivity"));
    } catch (error) {
      return null;
    }
  };
  const [state, setState] = useState();

  useEffect(() => {
    setState(getLsStaffsActivity());
  }, [props]);

  socket.on("connection", (socket) => {
    console.log("connected SocketIONavPanelDispatcher", socket.id);
  });
  socket.on("error", (data) => {
    //  console.log("socket error", data);
  });
  socket.on("StaffsActivity", (data) => {
    setState(data);
    window.localStorage.setItem("StaffsActivity", JSON.stringify(data));
  });

  return <StaffsActivity data={state} size={40} chain />;
};

export default SocketIONavPanelDispatcher;
