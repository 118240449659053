import React, { Component } from "react";
import { SettingOutlined, BugOutlined } from "@ant-design/icons";
import { Drawer, Menu, Button } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
 

import SocketIONavPanelDispatcher from "./SocketIONavPanelDispatcher";
export class NavPanel extends Component {
  state = { visible: false, staffsActivity: [] };
  //staff_last_activity_get

  showDrawer = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item key="active-users">
            <div style={{ marginTop: 0 }}>
              <div className="d-flex align-items-center">
                <SocketIONavPanelDispatcher />
              </div>
            </div>
          </Menu.Item>
    
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <span>
              <SettingOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Configuração do Tema"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
