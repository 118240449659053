import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = (props) => {
   
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />

        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={{
            pathname: `${AUTH_PREFIX_PATH}/login`,
            state: props.location.state,
          }}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
